import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from "zod";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Axios from "axios";

// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';

import FormInput from "../FormInput";

import { apiUrl } from "../../Vars";

export default function AdminProduct() {
  const productId = useParams().id;

  const [product, setProduct] = useState();
  const [productObject, setProductObject] = useState();
  const [isPennding, setIsPennding] = useState(true);
  const [err, setErr] = useState();
  const [value, setValue] = useState("");

  const [idle, setIdle] = useState(false);

  console.log(productObject);
  console.log(product);

  // const [editorState, setEditorState] = useState(
  //   () => EditorState.createEmpty(),
  // );)

  useEffect(() => {
    if (productId) {
      Axios.get(`${apiUrl}/api/admin/products/${productId}`, {
        headers: {
          "x-access-token": `${sessionStorage.getItem("token")}`,
        },
        withCredentials: true,
      })
        .then((res) => {
          setProduct(res.data);
          const productObject = {
            name: res.data[0].name,
            short_name: res.data[0].short_name,
            category: res.data[0].category,
            ref_number: res.data[0].ref_number.toString(),
            description: res.data[0].description,
            full_description: res.data[0].full_description,
            main_img: res.data[0].main_img.slice(10),
            brutto: res.data[0].brutto.toString().replace(".", ","),
            brutto_a: res.data[0].brutto_a.toString().replace(".", ","),
            brutto_b: res.data[0].brutto_b.toString().replace(".", ","),
            brutto_c: res.data[0].brutto_c.toString().replace(".", ","),
            brutto_d: res.data[0].brutto_d.toString().replace(".", ","),
            brutto_e: res.data[0].brutto_e.toString().replace(".", ","),
            netto: res.data[0].netto.toString().replace(".", ","),
            netto_a: res.data[0].netto_a.toString().replace(".", ","),
            netto_b: res.data[0].netto_b.toString().replace(".", ","),
            netto_c: res.data[0].netto_c.toString().replace(".", ","),
            netto_d: res.data[0].netto_d.toString().replace(".", ","),
            netto_e: res.data[0].netto_e.toString().replace(".", ","),
            vat: ((res.data[0].vat - 1) * 100).toString(),
            stock: res.data[0].stock.toString(),
            stock_rdz: res.data[0].stock.toString(),
            dimensions: res.data[0].dimensions,
            weight: res.data[0].weight,
          };
          console.log("first");
          setProductObject(productObject);
          setValue(productObject.full_description);
          setIsPennding(false);
          setErr(null);
        })
        .catch((err) => {
          console.log(err);
          setIsPennding(false);
          setErr(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // edit form section --------------------------------------

  const shema = z.object({
    name: string().min(1, { message: "Pole jest wymagane" }),
    short_name: string().min(1, { message: "Pole jest wymagane" }),
    description: string().min(1, { message: "Pole jest wymagane" }),
    category: string().min(1, { message: "Pole jest wymagane" }),
    ref_number: string().min(1, { message: "Pole jest wymagane" }),
    main_img: string().min(1, { message: "Pole jest wymagane" }),
    brutto: string().min(1, { message: "Pole jest wymagane" }),
    netto: string().min(1, { message: "Pole jest wymagane" }),
    brutto_a: string().min(1, { message: "Pole jest wymagane" }),
    netto_a: string().min(1, { message: "Pole jest wymagane" }),
    brutto_b: string().min(1, { message: "Pole jest wymagane" }),
    netto_b: string().min(1, { message: "Pole jest wymagane" }),
    brutto_c: string().min(1, { message: "Pole jest wymagane" }),
    netto_c: string().min(1, { message: "Pole jest wymagane" }),
    brutto_d: string().min(1, { message: "Pole jest wymagane" }),
    netto_d: string().min(1, { message: "Pole jest wymagane" }),
    brutto_e: string().min(1, { message: "Pole jest wymagane" }),
    netto_e: string().min(1, { message: "Pole jest wymagane" }),
    vat: string().min(1, { message: "Pole jest wymagane" }),
    stock: string().min(1, { message: "Pole jest wymagane" }),
    stock_rdz: string().min(1, { message: "Pole jest wymagane" }),
    dimensions: string().min(1, { message: "Pole jest wymagane" }),
    weight: string().min(1, { message: "Pole jest wymagane" }),
  });

  const { register, handleSubmit, formState, reset } = useForm({ resolver: zodResolver(shema) });

  const { errors } = formState;

  const productName = { name: "name", label: "Nazwa produktu", type: "text" };
  const productShort_Name = { name: "short_name", label: "Skrócona nazwa", type: "text" };
  const productCategory = { name: "category", label: "Kategoria produktu", type: "text" };
  const productRef = { name: "ref_number", label: "Numer referencyjny", type: "text" };
  const productImg = { name: "main_img", label: "Obrazek produktu", type: "text" };
  const productBrutto = { name: "brutto", label: "Brutto", type: "text" };
  const productNetto = { name: "netto", label: "Netto", type: "text" };
  const productBrutto_a = { name: "brutto_a", label: "Brutto Partner 30", type: "text" };
  const productNetto_a = { name: "netto_a", label: "Netto Partner 30", type: "text" };
  const productBrutto_b = { name: "brutto_b", label: "Brutto RDZ Autoryzowany", type: "text" };
  const productNetto_b = { name: "netto_b", label: "Netto RDZ Autoryzowany", type: "text" };
  const productBrutto_c = { name: "brutto_c", label: "Brutto Gabinety", type: "text" };
  const productNetto_c = { name: "netto_c", label: "Netto Gabinety", type: "text" };
  const productBrutto_d = { name: "brutto_d", label: "Brutto RDZ Rekomendowany", type: "text" };
  const productNetto_d = { name: "netto_d", label: "Netto RDZ Rekomendowany", type: "text" };
  const productBrutto_e = { name: "brutto_e", label: "Brutto RDZ", type: "text" };
  const productNetto_e = { name: "netto_e", label: "Netto RDZ", type: "text" };
  const productVat = { name: "vat", label: "Stawka VAT", type: "text" };
  const productQty = { name: "stock", label: "Stan magazynowy", type: "text" };
  const productQtyRdz = { name: "stock_rdz", label: "Stan magazynowy RDZ", type: "text" };
  const productDimensions = { name: "dimensions", label: "Wymiary", type: "text" };
  const productWeight = { name: "weight", label: "Waga", type: "text" };

  useEffect(() => {
    if (productId) {
      reset({ ...productObject });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const submitProduct = (data) => {
    console.log(data);
    const updatedObject = {
      name: data.name,
      short_name: data.short_name,
      category: data.category,
      ref_number: data.ref_number,
      description: data.description,
      full_description: value,
      main_img: `../assets/${data.main_img}`,
      brutto: parseFloat(data.brutto.replace(",", ".")),
      netto: parseFloat(data.netto.replace(",", ".")),
      brutto_a: parseFloat(data.brutto_a.replace(",", ".")),
      netto_a: parseFloat(data.netto_a.replace(",", ".")),
      brutto_b: parseFloat(data.brutto_b.replace(",", ".")),
      netto_b: parseFloat(data.netto_b.replace(",", ".")),
      brutto_c: parseFloat(data.brutto_c.replace(",", ".")),
      netto_c: parseFloat(data.netto_c.replace(",", ".")),
      brutto_d: parseFloat(data.brutto_d.replace(",", ".")),
      netto_d: parseFloat(data.netto_d.replace(",", ".")),
      brutto_e: parseFloat(data.brutto_e.replace(",", ".")),
      netto_e: parseFloat(data.netto_e.replace(",", ".")),
      vat: parseInt(data.vat) / 100 + 1,
      stock: parseInt(data.stock),
      stock_rdz: parseInt(data.stock_rdz),
      rating: 0,
      dimensions: data.dimensions,
      weight: data.weight,
      img_gallery: null,
    };

    Axios.post(
      `${apiUrl}/api/admin/products/update-product/${productId}`,
      {
        updatedObject,
      },
      {
        headers: {
          "x-access-token": `${sessionStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        setIdle(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // alert section ---------------------------------------------------

  useEffect(() => {
    if (idle === true) {
      const interval = setInterval(() => {
        setIdle(false);
      }, 1500);
      return () => clearInterval(interval);
    }
  }, [idle]);

  // render section ------------------------------------------------------------

  return (
    <>
      {err ? <div>{err}</div> : <></>}
      {isPennding && !productObject ? (
        <></>
      ) : (
        <div className="editProduct">
          <div className="editProduct__product">
            <form
              className="justify-start items-start"
              id="productForm"
              onSubmit={handleSubmit(submitProduct)}
            >
              <div className="productForm">
                <div className="img">
                  <img
                    src={`https://shop.genfactor.pl/assets/${productObject.main_img}`}
                    alt={productObject.name}
                  />
                  <FormInput
                    controls={productImg}
                    register={register}
                  />
                  <span className="input__err">{errors.main_img?.message}</span>
                </div>
                <div className="left">
                  <FormInput
                    controls={productName}
                    register={register}
                  />
                  <span className="input__err">{errors.name?.message}</span>
                  <FormInput
                    controls={productShort_Name}
                    register={register}
                  />
                  <span className="input__err">{errors.short_name?.message}</span>
                  <FormInput
                    controls={productCategory}
                    register={register}
                  />
                  <span className="input__err">{errors.category?.message}</span>
                  <FormInput
                    controls={productRef}
                    register={register}
                  />
                  <span className="input__err">{errors.ref_number?.message}</span>
                  <FormInput
                    controls={productDimensions}
                    register={register}
                  />
                  <span className="input__err">{errors.dimnesions?.message}</span>
                  <FormInput
                    controls={productWeight}
                    register={register}
                  />
                  <span className="input__err">{errors.weight?.message}</span>
                  <FormInput
                    controls={productQty}
                    register={register}
                  />
                  <span className="input__err">{errors.stock?.message}</span>
                  <FormInput
                    controls={productQtyRdz}
                    register={register}
                  />
                  <span className="input__err">{errors.stock_rdz?.message}</span>
                </div>
                <div className="right">
                  <div className="editProduct__price-row">
                    <div className="editProduct__price">
                      <FormInput
                        controls={productBrutto}
                        register={register}
                      />
                      <span className="input__err">{errors.brutto?.message}</span>
                    </div>
                    <div className="editProduct__price">
                      <FormInput
                        controls={productNetto}
                        register={register}
                      />
                      <span className="input__err">{errors.netto?.message}</span>
                    </div>
                  </div>
                  <div className="editProduct__price-row">
                    <div className="editProduct__price">
                      <FormInput
                        controls={productBrutto_a}
                        register={register}
                      />
                      <span className="input__err">{errors.brutto_a?.message}</span>
                    </div>
                    <div className="editProduct__price">
                      <FormInput
                        controls={productNetto_a}
                        register={register}
                      />
                      <span className="input__err">{errors.netto_a?.message}</span>
                    </div>
                  </div>
                  <div className="editProduct__price-row">
                    <div className="editProduct__price">
                      <FormInput
                        controls={productBrutto_b}
                        register={register}
                      />
                      <span className="input__err">{errors.brutto_b?.message}</span>
                    </div>
                    <div className="editProduct__price">
                      <FormInput
                        controls={productNetto_b}
                        register={register}
                      />
                      <span className="input__err">{errors.netto_b?.message}</span>
                    </div>
                  </div>
                  <div className="editProduct__price-row">
                    <div className="editProduct__price">
                      <FormInput
                        controls={productBrutto_c}
                        register={register}
                      />
                      <span className="input__err">{errors.brutto_c?.message}</span>
                    </div>
                    <div className="editProduct__price">
                      <FormInput
                        controls={productNetto_c}
                        register={register}
                      />
                      <span className="input__err">{errors.netto_c?.message}</span>
                    </div>
                  </div>
                  <div className="editProduct__price-row">
                    <div className="editProduct__price">
                      <FormInput
                        controls={productBrutto_d}
                        register={register}
                      />
                      <span className="input__err">{errors.brutto_d?.message}</span>
                    </div>
                    <div className="editProduct__price">
                      <FormInput
                        controls={productNetto_d}
                        register={register}
                      />
                      <span className="input__err">{errors.netto_d?.message}</span>
                    </div>
                  </div>
                  <div className="editProduct__price-row">
                    <div className="editProduct__price">
                      <FormInput
                        controls={productBrutto_e}
                        register={register}
                      />
                      <span className="input__err">{errors.brutto_e?.message}</span>
                    </div>
                    <div className="editProduct__price">
                      <FormInput
                        controls={productNetto_e}
                        register={register}
                      />
                      <span className="input__err">{errors.netto_e?.message}</span>
                    </div>
                  </div>
                  <div className="editProduct__vat-row w-60">
                    <FormInput
                      controls={productVat}
                      register={register}
                    />
                  </div>
                  <span className="input__err">{errors.vat?.message}</span>
                </div>
              </div>
              <div className="flex flex-col prod-desc">
                <label htmlFor="description">Krótki opis produktu</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="4"
                  {...register("description")}
                ></textarea>
                <span className="input__err">{errors.description?.message}</span>
              </div>

              <div className="form__input mt4">
                <label htmlFor="full_description">Pełny opis produktu</label>
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                />
                <br />
                {/* <Editor editorState={editorState} onChange={setEditorState} /> */}
              </div>
              <span className="input__err">{errors.description?.message}</span>

              {idle ? (
                <button className="btn-sm idle marginTop">Produkt został zaktualizowany</button>
              ) : (
                <button
                  className="btn-sm marginTop"
                  type="submit"
                >
                  Zaktualizuj produkt
                </button>
              )}
            </form>
          </div>
          <div className="marginTop"></div>
          <div className="flex gap-3">
            <NavLink to="/products">
              <button
                className="btn-sm marginTop w-60"
                form="productForm"
              >
                Wróć do listy
              </button>
            </NavLink>
            <NavLink to="/products/add">
              <button
                className="btn-sm marginTop w-60"
                form="productForm"
              >
                Dodaj produkt
              </button>
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
}
